


















// @vuese
// @group Forms
// Generic Form

import Component from "vue-class-component";
import { Prop, Emit } from 'vue-property-decorator';
import Vue from "vue";
import {Getter} from 'vuex-class'

@Component
/**
 * Generic form that appends styling to the form
 */
export default class GenericForm extends Vue {
  @Prop({default: undefined}) readonly id?: string;
  @Prop({default: undefined}) readonly className?: string;

  @Getter isLoading!: boolean

  /* tslint:disable:no-empty */
  /**
   * Fire when the form is submitted
   */
  @Emit()
  submit() {}
}
